import React from 'react';

export function Card({ className, ...props }) {
  return <div className={`bg-white shadow-lg rounded-lg ${className}`} {...props} />;
}

export function CardHeader({ className, ...props }) {
  return <div className={`px-4 py-5 border-b border-gray-200 ${className}`} {...props} />;
}

export function CardContent({ className, ...props }) {
  return <div className={`px-4 py-5 ${className}`} {...props} />;
}