import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, AreaChart, Area } from 'recharts';
import { AlertTriangle, CheckCircle, Bell, Satellite, Shield, Anchor, Plane, Users, Compass } from 'lucide-react';
import { Card, CardHeader, CardContent } from './components/ui/card';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';

const generateRandomData = (length, max) => {
  return Array.from({ length }, (_, i) => ({
    time: new Date(Date.now() - (length - i) * 60000).toISOString().substr(11, 8),
    value: Math.floor(Math.random() * max)
  }));
};

const ThreatLevel = ({ level }) => {
  const colors = {
    low: 'bg-green-500',
    moderate: 'bg-yellow-500',
    high: 'bg-red-500'
  };

  return (
    <div className={`inline-flex items-center px-2 py-1 rounded text-white ${colors[level]}`}>
      <AlertTriangle className="mr-1" size={16} />
      {level.charAt(0).toUpperCase() + level.slice(1)}
    </div>
  );
};

const satelliteNames = ['FalconEye', 'DubaiSat', 'KhalifaSat', 'Amal', 'YahSat', 'NaifSat', 'MizarSat', 'QumarSat'];
const dataTypes = ['Optical', 'Radar', 'Multispectral', 'Hyperspectral', 'Thermal', 'SAR'];
const regions = ['Dubai', 'Abu Dhabi', 'Sharjah', 'Ajman', 'Umm Al Quwain', 'Ras Al Khaimah', 'Fujairah'];
const departments = ['UAE Space Agency', 'National Security Council', 'Armed Forces', 'Civil Defense', 'Ministry of Interior', 'Ministry of Defense', 'National Emergency Crisis and Disasters Management Authority'];

const generateSatelliteData = () => {
  return Array.from({ length: 20 }, (_, i) => ({
    name: `${satelliteNames[Math.floor(Math.random() * satelliteNames.length)]}-${Math.floor(Math.random() * 10)}`,
    type: dataTypes[Math.floor(Math.random() * dataTypes.length)],
    data: `Monitoring ${regions[Math.floor(Math.random() * regions.length)]}`,
    explanation: `Analyzing ${dataTypes[Math.floor(Math.random() * dataTypes.length)]} data for security assessment`,
    status: Math.random() < 0.5 ? 'critical' : 'normal' // 50% chance of critical status
  }));
};

const RemoteSensingDashboard = () => {
  const [satelliteData, setSatelliteData] = useState(generateSatelliteData());
  const [sensorData, setSensorData] = useState(generateRandomData(20, 100));
  const [threatLevel, setThreatLevel] = useState('low');
  const [actionTaken, setActionTaken] = useState('Monitoring');
  const [notifiedDepartments, setNotifiedDepartments] = useState([{ name: departments[0], time: new Date().toISOString() }]);
  const [borderIncidents, setBorderIncidents] = useState(generateRandomData(7, 10));
  const [maritimeActivity, setMaritimeActivity] = useState(generateRandomData(7, 100));
  const [airspaceViolations, setAirspaceViolations] = useState(generateRandomData(7, 5));
  const [crowdMovement, setCrowdMovement] = useState(generateRandomData(7, 1000));
  const [desertPatrols, setDesertPatrols] = useState(generateRandomData(7, 20));

  useEffect(() => {
    const interval = setInterval(() => {
      // Update satellite data
      setSatelliteData(generateSatelliteData());

      // Update other data
      setSensorData(prevData => [...prevData.slice(1), { time: new Date().toISOString().substr(11, 8), value: Math.floor(Math.random() * 100) }]);
      setBorderIncidents(prevData => [...prevData.slice(1), { time: new Date().toISOString().substr(11, 8), value: Math.floor(Math.random() * 10) }]);
      setMaritimeActivity(prevData => [...prevData.slice(1), { time: new Date().toISOString().substr(11, 8), value: Math.floor(Math.random() * 100) }]);
      setAirspaceViolations(prevData => [...prevData.slice(1), { time: new Date().toISOString().substr(11, 8), value: Math.floor(Math.random() * 5) }]);
      setCrowdMovement(prevData => [...prevData.slice(1), { time: new Date().toISOString().substr(11, 8), value: Math.floor(Math.random() * 1000) }]);
      setDesertPatrols(prevData => [...prevData.slice(1), { time: new Date().toISOString().substr(11, 8), value: Math.floor(Math.random() * 20) }]);

      // Update threat level and actions
      const latestValue = sensorData[sensorData.length - 1].value;
      if (latestValue > 80) {
        setThreatLevel('high');
        setActionTaken('Initiating emergency protocols');
      } else if (latestValue > 50) {
        setThreatLevel('moderate');
        setActionTaken('Increased monitoring');
      } else {
        setThreatLevel('low');
        setActionTaken('Routine monitoring');
      }

      // Add new notification
      setNotifiedDepartments(prev => [
        ...prev.slice(-5), // Keep only the last 5 notifications
        { name: departments[Math.floor(Math.random() * departments.length)], time: new Date().toISOString() }
      ]);
    }, 1000); // Update every second for more dynamic feed

    return () => clearInterval(interval);
  }, [sensorData]);

  return (
    <div className="p-4 space-y-4">
      <h1 className="text-2xl font-bold mb-4">Remote Sensing Satellite Image Analysis</h1>
      
      <Card className="mb-6 max-h-96 overflow-y-auto">
        <CardHeader className="bg-gray-100 sticky top-0 z-10">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold">Real-Time Satellite Data Feeds</h2>
            <Satellite size={24} />
          </div>
        </CardHeader>
        <CardContent>
          <ul className="space-y-2">
            {satelliteData.map((satellite, index) => (
              <li key={index} className={`border-b pb-2 ${satellite.status === 'critical' ? 'bg-red-100 p-2 rounded' : ''}`}>
                <div className="flex items-center mb-1">
                  <Satellite className="mr-2" size={16} />
                  <span className={`font-semibold ${satellite.status === 'critical' ? 'text-red-600' : ''}`}>{satellite.name}</span>
                  <span className="ml-2 text-sm text-gray-500">({satellite.type})</span>
                </div>
                <p><strong>Data:</strong> {satellite.data}</p>
                <p className="text-sm text-gray-600">{satellite.explanation}</p>
                {satellite.status === 'critical' && (
                  <p className="text-red-600 font-bold mt-1">CRITICAL: Immediate attention required</p>
                )}
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>

      <div className="grid grid-cols-2 gap-4">
        <Card>
          <CardHeader>Real-time Sensor Data</CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={sensorData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="value" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>Threat Assessment</CardHeader>
          <CardContent>
            <Alert>
              <AlertTriangle className="h-4 w-4" />
              <AlertTitle>Current Threat Level</AlertTitle>
              <AlertDescription>
                <ThreatLevel level={threatLevel} />
              </AlertDescription>
            </Alert>
            <div className="mt-4">
              <h4 className="font-semibold">Action Taken:</h4>
              <div className="flex items-center mt-2">
                <CheckCircle className="mr-2" />
                <span>{actionTaken}</span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>Notifications Sent</CardHeader>
        <CardContent>
          <ul className="list-disc pl-5">
            {notifiedDepartments.map((dept, index) => (
              <li key={index} className="flex items-center mb-2">
                <Bell className="mr-2" size={16} />
                <span className="font-semibold">{dept.name}</span>
                <span className="ml-2 text-sm text-gray-500">
                  {new Date(dept.time).toLocaleTimeString()}
                </span>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>

      <div className="grid grid-cols-3 gap-4">
        <Card>
          <CardHeader>
            <div className="flex items-center">
              <Shield className="mr-2" size={20} />
              Border Incidents
            </div>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={borderIncidents}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <div className="flex items-center">
              <Anchor className="mr-2" size={20} />
              Maritime Activity
            </div>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={maritimeActivity}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="value" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <div className="flex items-center">
              <Plane className="mr-2" size={20} />
              Airspace Violations
            </div>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart data={airspaceViolations}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#ffc658" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Card>
          <CardHeader>
            <div className="flex items-center">
              <Users className="mr-2" size={20} />
              Crowd Movement Analysis
            </div>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={200}>
              <AreaChart data={crowdMovement}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
              </AreaChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <div className="flex items-center">
              <Compass className="mr-2" size={20} />
              Desert Patrol Activity
            </div>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={desertPatrols}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="value" stroke="#ff7300" />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default RemoteSensingDashboard;