import React from 'react';

export function Alert({ children, className, ...props }) {
  return <div className={`bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 ${className}`} role="alert" {...props}>{children}</div>;
}

export function AlertTitle({ children, className, ...props }) {
  return <h3 className={`font-bold ${className}`} {...props}>{children}</h3>;
}

export function AlertDescription({ children, className, ...props }) {
  return <p className={className} {...props}>{children}</p>;
}